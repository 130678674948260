<template>
  <div class="accyon__admin-content">
    <div class="p-grid">
      <div class="p-col-8 p-offset-2">
        <Card>
          <template #content>
            <div class="p-grid">
              <div class="p-col-6 p-offset-3">
                <div class="logo"></div>
              </div>
              <div class="p-col-6 p-offset-3">
                <div class="p-fluid">
                  <div class="p-field">
                    <label for="username">Usuario</label>
                    <InputText id="username" v-model="email" />
                  </div>
                  <div class="p-field">
                    <label for="password">Clave</label>
                    <Password
                      id="password"
                      :feedback="false"
                      v-model="password"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template #footer>
            <div class="p-col-6 p-offset-3">
              <Button label="Firmarse" @click="login" />
            </div>
          </template>
        </Card>
      </div>
    </div>
    <Toast />
  </div>
</template>

<script>
import firebase from "../Firebase";
export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  beforeMount() {
    const context = this;
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // not logged in
        context.$router.push({ name: "panel" });
      }
    });
  },
  methods: {
    login() {
      const context = this;
      if (this.password.trim() === "" || this.email.trim() === "") {
        context.$toast.add({
          severity: "warn",
          summary: "Autenticación falló",
          detail: "Debe ingresar su par de usuario y clave para iniciar sesión",
          life: 6000,
        });
        return false;
      }

      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          context.$router.push({ name: "panel" });
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;

          context.$toast.add({
            severity: "error",
            summary: "Autenticación falló",
            detail: `Error [${errorCode}]: ${errorMessage}`,
            life: 6000,
          });
        });
    },
  },
};
</script>

<style scoped>
.p-card {
  min-height: 20vh;
  margin-top: 19vh;
  margin-bottom: 28vh;
}

.logo {
  min-height: 20vh;
  margin: 0 auto;
  background-image: url("../assets/img/logo.png");
  background-repeat: no-repeat;
  background-size: 40%;
  background-position-x: center;
  background-position-y: bottom;
}
</style>
